import React, { useEffect } from "react";
import styled from "styled-components";
import Styles from "../assets/theme/styles";
import Colors from "../assets/theme/colors";
import Header from "../components/Header";
import Program from "../components/Program";
import COMIMG from "../components/ComImg";

import InstagramIcon from "../assets/image/svg/instagram.svg";
import BlogIcon from "../assets/image/svg/naverBlog.svg";
// import YoutubeIcon from '../assets/image/svg/youtube.svg'
import card1 from "../assets/image/web/progressive/card/card1.jpg";
import card2 from "../assets/image/web/progressive/card/card2.jpg";

import { useHistory } from "react-router";

const Contents = styled(Styles.Contents)``;

const Container = styled(Styles.Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${(props) =>
    props.white ? Colors.white : Colors.bg_bluegrey};

  @media all and (min-width: 641px) {
    padding-top: 200px;
    &:first-child {
      padding-top: 150px;
    }
  }

  @media all and (max-width: 640px) {
    padding-top: 100px;

    &:first-child {
      padding-top: 95px;
    }
  }
`;

const Paper = styled(Styles.Paper)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled(Styles.Title)`
  margin-bottom: 44px;
`;

const Description = styled(Styles.Description)``;

const Comment = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "GmarketSans", sans-serif;
  color: ${Colors.black};
  font-weight: 500;
  text-align: center;
  word-break: keep-all;

  @media all and (min-width: 641px) {
    font-size: 20px;
    margin-top: 149px;
    margin-bottom: 38px;
  }

  @media all and (max-width: 640px) {
    font-size: 15px;
    margin-top: 90px;
    margin-bottom: 30px;
  }
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  & div:first-child {
    margin-right: 30px;
  }

  @media all and (min-width: 641px) {
    margin-bottom: 149px;
  }

  @media all and (max-width: 640px) {
    margin-bottom: 90px;
  }
`;

const Button = styled(Styles.Button)`
  &::before {
    content: "";
    background: url(${(props) => props.icon}) center no-repeat;
  }

  @media all and (min-width: 641px) {
    width: 240px;
    height: 56px;
    font-size: 17px;
    font-weight: 500;
    &::before {
      width: 26px;
      height: 26px;
    }
    &::after {
      content: "${(props) => props.text}";
      padding-left: 10px;
    }
  }

  @media all and (max-width: 640px) {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    &::before {
      width: 24px;
      height: 24px;
    }
  }
`;

const FaqWrapper = styled.div`
  @media all and (min-width: 641px) {
    width: 852px;
    margin-top: 120px;
  }

  @media all and (max-width: 640px) {
    width: 100%;
    margin-top: 6px;
  }
`;

const Class = [
  { title: "현재 진행중인 프로그램이 없습니다." },
  // {
  //   title: "카드뉴스 원데이 클래스",
  //   webimage: card1,
  //   webimage2: card2,
  // },
];

const Community = ({ contract }) => {
  const { location } = useHistory();

  useEffect(() => {
    if (location.state === "progPro") {
      let pos = document.querySelector("#progPro").offsetTop;
      window.scrollTo({ top: pos, behavior: "smooth" });
    }
  }, []);

  return (
    <Contents>
      <Header
        color={Colors.black}
        mobileColor={Colors.green}
        border={Colors.grey_second}
        contract={contract}
      />
      <Container white>
        <Paper>
          <Title>함께 즐기는 커뮤니티 프로그램</Title>
          <Description>
            대전청년하우스만의 입주자 맞춤 프로그램은 당신의 일상에 활력을
            더해주고 휴식의 시간에는 힐링을 제공해 드리고 있습니다.
          </Description>
          <Program />
        </Paper>
      </Container>
      <Container id="progPro">
        <Paper>
          <Title>현재 진행중인 프로그램</Title>
          <FaqWrapper>
            {Class.map((Class, i) => (
              <COMIMG info={Class} key={i} />
            ))}
          </FaqWrapper>
          <Comment>
            대전청년하우스 SNS에서 더 자세한 내용을 확인해 주세요!
          </Comment>
          <ButtonWrap>
            <Button
              icon={InstagramIcon}
              text="인스타그램"
              onClick={() => {
                window.location.href =
                  "https://www.instagram.com/youthhouse_d/";
              }}
            />
            <Button
              icon={BlogIcon}
              text="네이버블로그"
              onClick={() => {
                window.location.href = "https://blog.naver.com/youthhouse__d";
              }}
            />
            {/* <Button icon={YoutubeIcon} text="유튜브 채널" onClick={() => { window.location.href='https://www.youtube.com/channel/UCYz2t8VJfapImoLfHmcTIMQ' }} /> */}
          </ButtonWrap>
        </Paper>
      </Container>
    </Contents>
  );
};

export default Community;
